import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Power Snatch 6-6-6-6\\@70% 1RM`}</p>
    <p>{`then,`}</p>
    <p>{`7:00 AMRAP of:`}</p>
    <p>{`50 Double Unders`}</p>
    <p>{`10-OHS’s (135/95)`}</p>
    <p>{`*`}{`*`}{`Bonus WOD`}{`*`}{`*`}</p>
    <p>{`3 Rounds for time of:`}</p>
    <p>{`15-DB Snatch (t0tal, 40/25)`}</p>
    <p>{`10-Burpee Box Jump Overs (24/20)`}</p>
    <p><strong parentName="p">{`*`}{`This Saturday is our free class so bring a friend!  8:00 & 9:00am at
The Ville, 10:00 & 11:00am at Louisville East.`}</strong></p>
    <p><strong parentName="p">{`*`}{`The CrossFit Games are live next week! We’ll be having a members get
together at the Barnett’s house Sunday, August 6th, from 12-6. We’ll
eat, watch the Games, and swim if you’d like. This is a family friendly
event so bring the kids! Please bring a side dish (if your last name
starts with A-M) or dessert (if your last name starts with N-Z). We’ll
supply the meat and drinks. Email, call or text Daniel to RSVP and for
directions.  Sunday’s 12:30 class will be cancelled for the party.`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      